import React from "react";
import { firebaseApp } from "../Firebase";
import "./DownloadCV.css";
import GetAppIcon from '@material-ui/icons/GetApp';
import useFileDownloader from "../hooks/useFileDownloader";

function DownloadCV() {
  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const getCV = async () => {
    const storageRef = firebaseApp.storage().ref();
    const fileRef = storageRef.child("Resume.pdf");

    fileRef
      .getDownloadURL()
      .then((url) => {

        downloadFile({
          name: "Downloading resume..",
          file:
            url ,
          filename: "Resume.pdf",
        });
      })
      .catch((error) => {
        alert("Oops!! Something went wrong.");
      });
  };

  
  return (
    <div className="downloadcv">
      <button type="submit" value="Resume" onClick={getCV}>
          Resume
          <span className="downloadcv__downicon"><GetAppIcon/></span>
      </button>
      {downloaderComponentUI}
    </div>
  );
}

export default DownloadCV;
