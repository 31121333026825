import React, { useEffect, useRef, useState } from "react";
import Group from "./Group";
import "./Skill.css";

function Skill() {
  const frontendTechnologies = [
    { id: 5, name: "Angular", efficiency: "85%" },
    { id: 6, name: "React.js", efficiency: "80%" },
    { id: 1, name: "HTML", efficiency: "80%" },
    { id: 2, name: "CSS", efficiency: "80%" },
    { id: 3, name: "Bootstrap", efficiency: "90%" },
    { id: 4, name: "Javascript", efficiency: "85%" },
   
  ];
  const backendTechnologies = [
    { id: 7, name: "Java", efficiency: "90%" },
    { id: 8, name: "Spring", efficiency: "90%" },
    { id: 9, name: "Spring boot", efficiency: "90%" },
    { id: 10, name: "Spring Security", efficiency: "80%" },
    { id: 11, name: "Hibernate", efficiency: "90%" },
    { id: 12, name: "Firebase", efficiency: "85%" },
  ];
  const databaseTechnologies = [
    { id: 13, name: "MySql", efficiency: "90%" },
    { id: 14, name: "MSSQL", efficiency: "80%" },
    { id: 15, name: "Redis(Cache)", efficiency: "80%" },
    { id: 16, name: "Mongodb", efficiency: "80%" },
    { id: 17, name: "RMQ(queue)", efficiency: "80%" },
    { id: 18, name: "HiveMQ(queue)", efficiency: "80%" },
  ];
  const deploymentTechnologies = [
    { id: 19, name: "Git", efficiency: "90%" },
    { id: 20, name: "Jenkins", efficiency: "90%" },
    { id: 21, name: "Docker", efficiency: "90%" },
    { id: 22, name: "Ansible", efficiency: "85%" },
    { id: 23, name: "Kubernates", efficiency: "75%" },
    { id: 24, name: "GCP", efficiency: "80%" },

  ];

  const domRef = useRef();
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);        
        observer.unobserve(domRef.current);
      }
    });
    
    observer.observe(domRef.current);
    
    return () => observer.unobserve(domRef.current);
  }, []);
  
  return (
    <div className="skill header__style">
      <h5>Skills</h5>
      <h6 className="header__explanationstyle">My tech stack</h6>
      <div className={`skill__group  ${isVisible && "is-visible"}`}  ref={ domRef }>
        <Group
          header="Backend"
          experience="More than 5 years"
          technologies={backendTechnologies}
        />
        <Group
          header="Frontend"
          experience="More than 4 years"
          technologies={frontendTechnologies}
        />
        <Group
          header="DevOps"
          experience="More than 3 years"
          technologies={deploymentTechnologies}
        />
        <Group
          header="Database"
          experience="More than 5 years"
          technologies={databaseTechnologies}
        />
      </div>
    </div>
  );
}

export default Skill;
