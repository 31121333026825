import React from "react";
import "./Introduction.css";
import dp from "../assets/dp4.png";
const yearsOfExperience = Math.floor( Math.abs(new Date() - new Date('2016-06-12')) / (1000 * 60 * 60 * 24 * 365.25));

function Introduction() {
  return (
    <div className="introduction">
      <img
        src={dp}
        alt=""
      />
      <div className="introduction__details">
        <h2>
          A passionate{" "}
          <span className="introduction__highlight">Backend developer</span>{" "}
          with an affinity towards{" "}
          <span className="introduction__highlight">DevOps</span>
          <span> and </span>
          <span className="introduction__highlight">User Interface Design</span> as well, with over{" "}
          <span className="introduction__highlight">
            {yearsOfExperience} years of industry experience
          </span>{" "}
          in helping organizations build impactful and intuitive products.
        </h2>
        <h3>
          My expertise is to integrate complex products, which interact
          seamlessly with the ecosystem to ascertain maximum user adoption.
        </h3>
        <div className="introduction__strongpoints">
          <div className="introduction__strongpoint1">
              <h4>Code is poetry</h4>
              <p>I enjoy building tech products for maximum utilisations needed for sustainable businesses.</p>
          </div>
          <div className="introduction__strongpoint2">
              <h4>Team Player</h4>
              <p>I always loved working with like-minded 
                  team to deliver the best product.
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
