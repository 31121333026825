function TimeFormatUtils() {
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  let newDate = new Date();
  let date = newDate.getDate();
  let month = monthNames[newDate.getMonth()];
  let year = newDate.getFullYear();

  return `${month} ${date}, ${year}`;
}

export default TimeFormatUtils;
