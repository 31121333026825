import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import db from "../../Firebase";
import "./PostPage.css";
import BackspaceIcon from "@material-ui/icons/Backspace";
import dp from "../../assets/dp4.png";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { FacebookButton, FacebookCount, LinkedInButton } from "react-social";

function PostPage() {
  const params = useParams();
  const [post, setPost] = useState([]);
  const postId = params;
  const history = useHistory();
  const gotoBlogPage = (event) => {
    event.preventDefault();
    history.push("/blog");
  };
  useEffect(() => {
    const variable = { postId: postId };
    console.log(postId);
    db.collection("blog")
      .where("desiredUrl", "==", postId.postId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          setPost(doc.data());
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }, []);

  if (post.content) {
    return (
      <div className="postpage">
        {/* <h3>{"Amrit Jeet"}</h3>
        <h3>{post.publishedTime}</h3> */}
        <h2>
          <span>{post.title}</span>
          <span onClick={gotoBlogPage}>
            <BackspaceIcon />
          </span>
        </h2>
        <div className="postpage__auther">
          <div className="postpage__autherimage">
            <img src={dp} alt="" />
          </div>
          <div className="postpage__autherdetails">
            <h3>{"Amrit Jeet"}</h3>
            <h3>{post.publishedTime}</h3>
          </div>
          <div className="postpage__sociallinks">
            <LinkedInButton
              url={`https://amritjeet.com/blog/post/${post.desiredUrl}`}
              element="a"
              title={post.desiredUrl}
            >
              <LinkedInIcon />
            </LinkedInButton>
            <InstagramIcon />
            <FacebookButton
              url={`https://amritjeet.com/blog/post/${post.desiredUrl}`}
              appId={`906146293638965`}
              element="a"
            >
              <FacebookIcon />
            </FacebookButton>
            <TwitterIcon />
          </div>
        </div>

        <div className="postbase__blogimage">
          <img src={post.imageUrl} />
        </div>
        <div
          className="postpage__panel"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
    );
  } else {
    return <div style={{ width: "80%", margin: "auto" }}>loading...</div>;
  }
}

export default PostPage;
