import { Button } from "@material-ui/core";
import { message } from "antd";
import React, { useState } from "react";
import QuillEditor from "../QuillEditor/QuillEditor";
import db, { firebaseApp } from "../../Firebase";
import "./Create.css";
import "../Antd.css";
// import "antd/dist/antd.css";
import { useHistory } from "react-router";
import TimeFormatUtils from "../TimeFormatUtils"

function Create() {
  const [desiredUrl, setDesiredUrl] = useState("");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const onDesiredUrlChange = (value) =>{
    setDesiredUrl(value.target.value);
  }
  const onTitleChange = (value) => {
    setTitle(value.target.value);
  };
  const onEditorChange = (value) => {
    setContent(value);
  };
  const onFilesChange = (file) => {
    setFiles(file);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if(desiredUrl == "" || desiredUrl.trim() == ""){
      alert("Please provide desired url for this blog.");
      return;
    }
    if (title == "" || title.trim() == "") {
      alert("Please provide description for this blog.");
      return;
    }
    setDesiredUrl("");
    setTitle("");
    setContent("");
    const variables = {
      desiredUrl: desiredUrl,
      title: title,
      content: content,
      publishedTime: TimeFormatUtils(),
      userId: "01",
    };
    db.collection("blog")
      .add(variables)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        message.success("Post Created");
        setTimeout(() => {
          history.push("/blog");
        }, 2000);
      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <div className="create">
      <h3>Editor</h3>
      <input
        type="text"
        placeholder="Please provide desired url here."
        onChange={onDesiredUrlChange}
      />
      <input
        type="text"
        placeholder="Please provide title here."
        onChange={onTitleChange}
      />

      <QuillEditor
        placeholder={"Start posting something.."}
        onEditorChange={onEditorChange}
        onFilesChange={onFilesChange}
      />
      <form onSubmit={onSubmit}>
        <div style={{ textAlign: "center", margin: "2rem" }}>
          <Button
            size="large"
            type="submit"
            className="create__submit"
            onSubmit={onSubmit}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Create;
