import React from "react";
import "./EmailService.css";
import emailjs from "emailjs-com";

function EmailService() {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.init("user_o3tfklIKuxtRGGWik3ylI");
    emailjs
      .sendForm(
        "service_sq2kbsb",
        "template_9uwx11i",
        e.target,
        "user_o3tfklIKuxtRGGWik3ylI"
      )
      .then(
        (result) => {
            alert("Email received successfully!!");
            window.location.reload(); 
        },
        (error) => {
            alert("Oops!! Something went wrong.");
        }
      );
  };

  return (
    <div className="emailservice">
      <h5>Queries?</h5>
      <h6 className="header__explanationstyle">Wanna ask anything?</h6>
      <form className="contact-form" onSubmit={sendEmail}>
        <input
          type="text"
          placeholder="Mention your name*"
          name="from_name"
          required
        />
        <input
          type="email"
          placeholder="Mention your email*"
          name="from_email"
          required
        />
        <input
          type="text"
          placeholder="Specify your intent*"
          name="subject"
          required
        />
        <textarea
          name="message"
          placeholder="Specify your query*"
          required
        />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
}

export default EmailService;
