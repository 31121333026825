import { Button } from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, { useEffect, useState } from "react";
import "./ScrollButton.css";

function ScrollButton() {
  const [show, handleShow] = useState(false);

  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  };
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar)
     return () => window.removeEventListener("scroll", transitionNavBar)
 }, [])

  return (
    <div className={`scrollbutton ${show && 'scrollbutton__hidden'}`}>
      <Button onClick={scrollToTop}><ArrowUpwardIcon/></Button>
    </div>
  );
}

export default ScrollButton;
