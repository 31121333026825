import "./App.css";
import BottomLine from "./BottomLine/BottomLine";
import Cover from "./Cover/Cover";
import Introduction from "./Introduction/Introduction";
import ProfessionalJourney from "./ProfessionalJourney/ProfessionalJourney";
import Skill from "./Skill/Skill";
import ScrollButton from "./ScrollButton/ScrollButton";
import Services from "./Service/Services";
import EmailService from "./Email/EmailService";
import DownloadCV from "./DownloadCV/DownloadCV";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Create from "./Coding/Create/Create";
import BlogPage from "./Coding/BlogPage/BlogPage";
import PostPage from "./Coding/PostPage/PostPage";
import EditBlog from "./Coding/EditBlog/EditBlog";

function App() {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/create">
            <Create/>
          </Route>
          <Route path="/blog/post/:postId">
            <PostPage/>
          </Route>
          <Route path="/blog/edit/:postId">
            <EditBlog/>
          </Route>
          <Route path="/blog">
            <BlogPage/>
          </Route>
          <Route path="/">
            <div className="app__default">
              <Cover />
              <Introduction />
              <Services />
              <Skill />
              <ProfessionalJourney />
              <DownloadCV />
              <EmailService />
              <BottomLine />
              <ScrollButton />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
