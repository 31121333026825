import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import db, { firebaseApp } from "../../Firebase";
import QuillEditor from "../QuillEditor/QuillEditor";
import { Button } from "@material-ui/core";
import { message } from "antd";
import TimeFormatUtils from "../TimeFormatUtils";

import "./EditBlog.css";

function EditBlog() {
  const history = useHistory();
  const params = useParams();
  const postId = params;

  const [post, setPost] = useState([]);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [desiredUrl, setDesiredUrl] = useState("");
  const [recievedDesiredUrl, setReceivedDesiredUrl] = useState("");
  const [title, setTitle] = useState("");
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState("");
  const [seoImageAsFile, setSeoImageAsFile] = useState("");
  const [seoImageAsUrl, setSeoImageAsUrl] = useState("");

  const gotoBlogPage = (event) => {
    event.preventDefault();
    history.push("/blog");
  };
  const onDesiredUrlChange = (value) => {
    setDesiredUrl(value.target.value);
  };
  const onTitleChange = (value) => {
    setTitle(value.target.value);
  };
  const onEditorChange = (value) => {
    setContent(value);
  };
  const onFilesChange = (file) => {
    setFiles(file);
  };
  const handleImageAsFile = (e) => {
    e.preventDefault();
    setImageAsFile(e.target.files[0]);
  };
  const handleSeoImageAsFile = (e) => {
    // e.preventDefault();
    setSeoImageAsFile(e.target.files[0]);

    const uploadTask = firebaseApp
      .storage()
      .ref(`/blog_images/${e.target.files[0].name}`)
      .put(e.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        firebaseApp
          .storage()
          .ref("blog_images")
          .child(e.target.files[0].name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            if (fireBaseUrl && fireBaseUrl != "") {
              setSeoImageAsUrl(fireBaseUrl);
            }
          });
      }
    );
  };
  useEffect(() => {
    db.collection("blog")
      .where("desiredUrl", "==", postId.postId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.desiredUrl);
          setDesiredUrl(doc.data().desiredUrl);
          setReceivedDesiredUrl(doc.data().desiredUrl);
          onEditorChange(doc.data());
          setPost(doc.data());
          setImageAsUrl(doc.data().imageUrl);
          setSeoImageAsUrl(doc.data().seoImageUrl);
          setTitle(doc.data().title);
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    if (desiredUrl == "" || desiredUrl.trim() == "") {
      alert("Please provide desired url for this blog.");
      return;
    }
    if (title == "" || title.trim() == "") {
      alert("Please provide description for this blog.");
      return;
    }
    if (imageAsFile && imageAsFile.name) {
      const uploadTask = firebaseApp
        .storage()
        .ref(`/blog_images/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          console.log(snapShot);
        },
        (err) => {
          console.log(err);
        },
        () => {
          firebaseApp
            .storage()
            .ref("blog_images")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              if (fireBaseUrl && fireBaseUrl != "") {
                alert(seoImageAsFile.name);
                const variables = {
                  desiredUrl: desiredUrl,
                  imageUrl: fireBaseUrl,
                  seoImageUrl: seoImageAsUrl,
                  title: title,
                  content: content ? content : post.content,
                  publishedTime: TimeFormatUtils(),
                  userId: "01",
                };
                saveFirestore(variables);
              }
            });
        }
      );
    } else {
      const variables = {
        desiredUrl: desiredUrl,
        imageUrl: imageAsUrl,
        seoImageUrl: seoImageAsUrl,
        title: title,
        content: content ? content : post.content,
        publishedTime: TimeFormatUtils(),
        userId: "01",
      };
      saveFirestore(variables);
    }

    function saveFirestore(variables) {
      db.collection("blog")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.data().desiredUrl == recievedDesiredUrl) {
              doc.ref.update(variables);

              setDesiredUrl("");
              setTitle("");
              setContent("");
              setReceivedDesiredUrl("");
              setPost([]);
              setFiles([]);
              setSeoImageAsUrl("");
              setImageAsUrl("");
              setImageAsFile("");
              setSeoImageAsFile("");
              setTimeout(() => {
                history.push("/blog");
              }, 2000);
            }
          });
        });
    }
  };
  if (post.content) {
    return (
      <div className="editblog__page">
        <div className="editblog">
          <h3>Editor</h3>

          <input
            type="text"
            placeholder="Please provide desired url here."
            onChange={onDesiredUrlChange}
            value={desiredUrl}
          />
          <input
            type="text"
            placeholder="Please provide title here."
            onChange={onTitleChange}
            value={title}
          />
          <div className="editblog__blogimageuploads">
            <label>Choose file for blog image</label>
            <input
              type="file"
              className="editblog__blogimage"
              onChange={handleImageAsFile}
            />
          </div>
          <div className="editblog__seoimageuploads">
            <label>Choose file for seo image</label>
            <input
              type="file"
              className="editblog__blogimage"
              onChange={handleSeoImageAsFile}
            />
          </div>
          <QuillEditor
            placeholder={"Start posting something.."}
            onEditorChange={onEditorChange}
            onFilesChange={onFilesChange}
            data={post.content}
          />
          <form onSubmit={onSubmit}>
            <div style={{ textAlign: "center", margin: "2rem" }}>
              <Button
                size="large"
                type="submit"
                className="create__submit"
                onSubmit={onSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return <div className="editblog__loading">Processing...</div>;
  }
}

export default EditBlog;
