import React, { Fragment, useState } from "react";
import "./Group.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Group({ header, experience, technologies, efficiency }) {
  const [isVisible, setVisible] = useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault();
    let temp = !isVisible;
    setVisible(temp);
  };
  const style = {
    transform: isVisible ? "rotate(-180deg)" : "",
    transition: "transform 600ms ease",
    color: "#ff914d", 
  };
  return (
    <div className="group">
      <div className="group__header" data-mssg={header} >
      {/* onClick={handleButtonClick} */}
        <div className="group__headerdetails">
          <span className="subheader">{header}</span>
          <span className="subheader__description">{experience}</span>
        </div>
        <ExpandMoreIcon style={style} />
      </div>
      <ul>
        {technologies?.map(function (techName, index) {
            return (
              <Fragment key={techName.id}>
                <li >
                  <div className="group__techname">{techName.name}</div>
                  <div className="group__efficiency">
                    {/* {techName.efficiency} */}
                  </div>
                </li>
                <div className="group__progress">
                  <div
                    className="group__bar"
                    style={{ width: `${techName.efficiency}` }}
                  ></div>
                </div>
              </Fragment>
            );
          })}
      </ul> 
      {/* <ul>
        {isVisible &&
          technologies?.map(function (techName, index) {
            return (
              <Fragment key={techName.id}>
                <li >
                  <div className="group__techname">{techName.name}</div>
                  <div className="group__efficiency">{techName.efficiency}</div>
                </li>
                <div className="group__progress">
                  <div
                    className="group__bar"
                    style={{ width: `${techName.efficiency}` }}
                  ></div>
                </div>
              </Fragment>
            );
          })}
      </ul> */}
    </div>
  );
}

export default Group;
