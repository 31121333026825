import React, { useEffect, useState } from "react";
import { Card, Icon, Avatar, Col, Typography, Row } from "antd";
import "./BlogPage.css";
import db from "../../Firebase";
import Meta from "antd/lib/card/Meta";
import "../Antd.css";
import { useHistory } from "react-router-dom";

function BlogPage() {
  const history = useHistory();

  const [blogs, setBlogs] = useState([]);

  const navigateToPortfolio = (event) => {
    event.preventDefault();
    history.push("/");
  };
  useEffect(() => {
    db.collection("blog")
      .get()
      .then((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach(async (subscription) => {
          console.log(subscription);
          if (subscription) {
            temp.push({
              id: subscription.id,
              desiredUrl: subscription.data().desiredUrl,
              title: subscription.data().title,
              content: subscription.data().content,
              publishedTime: subscription.data().publishedTime,
            });
          } else {
            alert("Cannot get blogs!!");
          }
        });
        setBlogs(temp);
      });
  }, []);

  const renderCards = blogs.map((blog, index) => {
    return (
      <Col key={index} lg={8} md={12} xs={24}>
        <Card
          hoverable
          style={{ width: 370, marginTop: 16 }}
          actions={[
            <span onClick={navigateToPortfolio}>
              <Avatar>
                <span className="blogpage__auther">A</span>
              </Avatar>{" "}
              &nbsp; {"Amrit Jeet"}
            </span>,
          ]}
        >
          <Meta title={blog.title} description={blog.publishedTime} />
          <a href={`/blog/post/${blog.desiredUrl}`}>
            <div style={{ height: 150, overflowY: "scroll", marginTop: 10 }}>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
          </a>
        </Card>
      </Col>
    );
  });

  return (
    <div className="blogpage" style={{ margin: "auto" }}>
      <h2>Blogs</h2>
      <div className="blogpage__panel">
        <Row gutter={[16, 8]}>{renderCards}</Row>
      </div>
    </div>
  );
}

export default BlogPage;
