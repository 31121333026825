import React from "react";
import "./Cover.css";
import logo from "../assets/logo.png";

function Cover() {
  return (
    <div className="cover">
       <img
        src={logo}
        alt=""
      />
      <h1>
        <span className="cover__initials">I am </span>
        <span className="cover__namedecoration"><span className="cover__name">amrit</span></span>
        <span className="cover__initials cover__dot">.</span>
      </h1>
      <h4 className="">Full Stack Web Developer</h4>
    </div>
  );
}

export default Cover;
