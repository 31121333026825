// import firebase from "firebase";
import firebase from 'firebase/app'
 import "firebase/storage"
import 'firebase/firestore';
import 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyAm1a9TfLlP2a8sbs5JnbIxvqT21P6BVYo",
  authDomain: "amrit-portfolio.firebaseapp.com",
  projectId: "amrit-portfolio",
  storageBucket: "amrit-portfolio.appspot.com",
  messagingSenderId: "88180672286",
  appId: "1:88180672286:web:831a348eebd3e8cab56b0f",
  measurementId: "G-R22GXYZVPB",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
// const auth=firebase.auth();

export { firebaseApp };
export default db;
