import React from "react";
import Service from "./Service";
import "./Services.css";

function Services() {
  const devops = {
    id: 1,
    name: "Devops Architect",
    data: [
      {did:11, details:"I can build scalable, highly available and resilient application architecture."},
      {did:12, details:"I can create automated pipelines for faster development to end user cycle."},
      {did:13, details:"Cost, performance and efficiency while working on cloud environment."},
    ],
  };
  const frontenddeveloper = {
    id: 2,
    name: "Frontend Developer",
    data: [
      {did:21, details:"I develop the user interface."},
      {did:22, details:"Responsive Stylings using CSS and bootstrap."},
      {did:23, details:"I follow coding standards."},
      {did:24, details:"Systematic and Organized UI designs."},
      {did:25, details:"Proper error handling in UI side."},
      {did:26, details:"UI-API Integrations"},
      {did:27, details:"Secure UI operations with help of backend APIs."},
      {did:28, details:"Deploy on host machine and DNS routing to that."}
    ],
  };
  const backenddeveloper = {
    id: 3,
    name: "Backend Developer",
    data: [
      {did:31, details:"I develop secured backend APIs."},
      {did:32, details:"Ensure data authentication and authorisation."},
      {did:33, details:"Proper exception handling on backend side."},
      {did:34, details:"Backend to database interactions."},
      {did:35, details:"Caching for faster data retrieval."},
      {did:36, details:"UI-API Integrations"},
      {did:37, details:"Deploy backend on host machine for consumption."}
    ],
  };
  return (
    <div className="services header__style">
      <h5>Services</h5>
      <h6 className="header__explanationstyle">What I offer?</h6>
      <div className="services__details">
        <Service service={backenddeveloper} />
        <Service service={devops} />
        <Service service={frontenddeveloper} />
      </div>
    </div>
  );
}

export default Services;
