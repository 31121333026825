import React from "react";
import "./ProfessionalJourney.css";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from '@material-ui/core/useMediaQuery';


function ProfessionalJourney() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "6px 16px",
      border: "1px solid lightgray",
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",

      '&:hover': {
        boxShadow: "0 0 5px 2px lightgray"
      }
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    professionaljourney__heading:{
      fontSize: "18px",
      lineHeight: "28px"
    }
  }));
  const classes = useStyles();
  const matches = useMediaQuery('(max-width: 750px)');

  return (
    <div className="professionaljourney header__style">
      <h5>Employment Timeline</h5>
      <h6 className="header__explanationstyle">My professional journey</h6>
      <div className="professionaljourney__details">
        {matches && 
            <Timeline align="left">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <LaptopMacIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Full Stack developer
                  </Typography>
                  <Typography color="textSecondary">Axidio</Typography>
                  <Typography variant="body2" color="textSecondary">
                    2019-Present
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary" variant="outlined">
                  <LaptopMacIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Full Stack Developer
                  </Typography>
                  <Typography color="textSecondary">Carzonrent</Typography>
                  <Typography variant="body2" color="textSecondary">
                    2018-2019
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary" variant="outlined">
                  <LaptopMacIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Full Stack Developer
                  </Typography>
                  <Typography color="textSecondary">Equence</Typography>
                  <Typography variant="body2" color="textSecondary">
                    2016-2018
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        }
        {!matches && 
          <Timeline align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Full Stack developer
                </Typography>
                <Typography color="textSecondary">Axidio</Typography>
                <Typography variant="body2" color="textSecondary">
                  2019-Present
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary" variant="outlined">
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Full Stack Developer
                </Typography>
                <Typography color="textSecondary">Carzonrent</Typography>
                <Typography variant="body2" color="textSecondary">
                  2018-2019
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary" variant="outlined">
                <LaptopMacIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Full Stack Developer
                </Typography>
                <Typography color="textSecondary">Equence</Typography>
                <Typography variant="body2" color="textSecondary">
                  2016-2018
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        }
      </div>
    </div>
  );
}

export default ProfessionalJourney;
