import React, { useState } from "react";
import "./Service.css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';


function Service({ service }) {
  const [visible, setVisible] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid lightgray",
      padding: theme.spacing(2, 4, 3),
      minHeight: "150px",
      maxWidth: "550px",
      outline: "none",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
  }));
  const classes = useStyles();

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div className="service">
      <span className="subheader">{service.name}</span>
      <span
        className="service__link subheader__description"
        onClick={handleOpen}
      >
        <span>Learn more</span> <ArrowRightAltIcon />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visible}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              {service.name}
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </h2>
            <ul id="transition-modal-description">
              {service.data?.map((data) => {
                return <li key={data.did}>{data.details}</li>;
              })}
            </ul>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Service;
