import React from 'react'
import "./BottomLine.css"

function BottomLine() {
    return (
        <div className="bottomline">
            <div className="bottomline__quote">
                <span>You have a new project?</span>
                <span>Then, let's make something great together!</span>
            </div>
            <div className="bottomline__contacts">
                <h4>(+91) 973 102 7069</h4>
                <a href="mailto:eramritghose@gmail.com?subject=Specify%20your%20intent&body=Specify%20your%20query">eramritghose@gmail.com</a>
            </div>
        </div>
    )
}

export default BottomLine
